.content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.markdown-section {
/*   flex: 1 1 0%; */
  margin: 0 48px;
}
.nav {
  width: var(--toc-width, 700px);
/*   align-self: flex-start;
  flex: 0 0 auto; */
}
aside.nav.nothing {
  width: 0;
}

.page_toc {
  position: fixed;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.07);
  border-image-slice: 1;
  padding-left: 5px;
}

.page_toc a > * {
  pointer-events: none;
}

.page_toc code {
  background-color: #f8f8f8;
  border-radius: 2px;
  color: #e96900;
  font-family: 'Roboto Mono', Monaco, courier, monospace;
  font-size: 0.8rem;
  margin: 0 2px;
  padding: 3px 5px;
}

.page_toc p.title {
  margin: 0px 0 0px 9px;
  padding-bottom: 5px;
  font-weight: 600;
  font-size: 1.2em;
}
.page_toc .anchor:hover:after {
  content: "";
}

.page_toc ul {
  list-style-type: none;
  margin-top: 0px;
  padding-left: 10px;
  color: var(--text-color-base, black);
  text-decoration: none;
  font-weight: 300;
  line-height: 1.6em;
}

.page_toc ul a:hover span {
  color: var(--text-color-tertiary, #42b983);
  border-bottom: none !important;
  text-decoration:none !important;
}

.page_toc ul a {
  color: var(--text-color-base, black);
  text-decoration: none;
  font-weight: 300;
  line-height: 1.6em;
}

@media screen and (max-width: 1300px) {
  .page_toc {
    position: relative;
    left: 0;
    top: -20px;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 1.0em;
  }
  .page_toc a:before {
    content: "- ";
  }
  .nav {
    margin: 0 auto;
    width: 800px;
  }
  .page_toc p.title {
    font-weight: 300;
    font-size: 1.8em;
  }
  .content {
    display: block;
  }
  .markdown-section {
    margin: 0 auto;
  }
}

.page_toc .active {
  border-left: 5px solid;
  color: var(--theme-color, #42b983);
  padding-left: 10px;
}
